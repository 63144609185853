import { createRoutine } from "redux-saga-routines";

export const getRefData = createRoutine("GET_REF_DATA");

export const INITIAL_STATE = {
  appSettings: {
    programYear: 0,
    areVolAppNextYearRegistrationsEnabled: false,
    isVolunteerDataResetEnabled: false,
  },
  isLoading: false,
  hasBeenRequested: false,
  isSuccess: false,
  isError: false,
  message: "",
  genders: [],
};

export default (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case getRefData.TRIGGER:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        hasBeenRequested: true,
      };
    case getRefData.SUCCESS:
      return { ...state, isSuccess: true, isError: false, ...payload };
    case getRefData.FAILURE:
      return { ...state, isSuccess: false, isError: true, message: payload };
    case getRefData.FULFILL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
