import { createRoutine } from 'redux-saga-routines';

import { STARTUP } from './index'


export const getSponsor = createRoutine('getSponsor');
export const updateSponsor = createRoutine('updateSponsor');
export const createSponsor = createRoutine('createSponsor');

export const login = createRoutine('Login');
export const logout = createRoutine('Logout');

export const forgotPassword = createRoutine('forgotPassword');
export const confirmForgotPassword = createRoutine('confirmForgotPassword');
export const changePassword = createRoutine('changePassword');

export const baseURLConstant = createRoutine('BaseURLConstant');

export const INITIAL_STATE = {
    count: 0,
    // token: null,
    sponsor: {
        error: false,
        success: false,
        loading: false,
        currentUser: {}
    },
    forgot: {
        success: false,
        loading: false,
        codeSuccess: false,
        codeLoading: true
    },
    login: {
        success: false,
        loading: false
    },
    change: {
        success: false,
    }
};

export default (state = INITIAL_STATE, { type, payload }: {type: string; payload: any}) => {
    switch (type) {
        case STARTUP.SUCCESS:
            return { ...state, token: payload }

        // SPONSOR
        case getSponsor.TRIGGER:
            return { ...state, sponsor: { loading: true, success: false, error: false } };
        case getSponsor.SUCCESS:
            return { ...state, sponsor: { loading: false, success: true, error: false, currentUser: payload } };
        case getSponsor.FAILURE:
            return { ...state, sponsor: { loading: false, success: false, error: true, message: payload } };

        case updateSponsor.TRIGGER:
            return { ...state, sponsor: { loading: true, success: false, error: false } };
        case updateSponsor.SUCCESS:
            return { ...state, sponsor: { loading: false, success: true, error: false, currentUser: payload } };
        case updateSponsor.FAILURE:
            return { ...state, sponsor: { loading: false, success: false, error: true, message: payload } };

        case createSponsor.TRIGGER:
            return { ...state, sponsor: { loading: true, success: false, error: false } };
        case createSponsor.SUCCESS:
            return { ...state, sponsor: { loading: false, success: true, error: false, currentUser: payload } };
        case createSponsor.FAILURE:
            return { ...state, sponsor: { loading: false, success: false, error: true, message: payload } };

        case baseURLConstant.TRIGGER:
            return { ...state, loading: false, error: false, message: '', baseUrlConstant: payload.url }

        default:
            return { ...state }
    }
}
