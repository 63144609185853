import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { createSponsor, getSponsor } from "../redux/account.redux";
import Api from "../services/ApiCaller";

// SPONSOR
function* GetSponsorRequest(api) {
  try {
    const { res = {} } = yield call(Api.callServer, api, {});
    if (res && res.sponsor && res.sponsor.id) {
      yield put(getSponsor.success(res.sponsor));
    } else {
      yield put(getSponsor.failure(''));
    }
  } catch (e) {
    console.error(e);
    yield put(getSponsor.failure(e));
  }
}

function* CreateSponsorRequest(api: any, { payload }: {payload: any}) {
  try {
    const { res = {} } = yield call(Api.callServer, api, payload.user);
    if (res && res.success) {
      yield put(createSponsor.success(res.sponsor));
    } else {
      yield put(createSponsor.failure(''));
    }
  } catch (e) {
    toast(e.message || e);
    yield put(createSponsor.failure(e));
  }
}


export default (api: { get: (arg0: string) => any; post: (arg0: string, arg1: any) => any; }) => {
  const getSponsorApi = () => api.get("/Sponsors");
  const createSponsorApi = (data: any) => api.post("/Sponsors", data);

  return [
    takeLatest(getSponsor.TRIGGER, GetSponsorRequest, getSponsorApi),
    takeLatest(createSponsor.TRIGGER as any, CreateSponsorRequest, createSponsorApi),
  ];
};
