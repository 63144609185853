import React from "react";
import { useSelector } from "react-redux";
import { LoginAngel, CandyCaneStrip } from "../../../constants/images";
import { getOrganizationState } from "../../../selectors/organization.selector";
import Button from "../button/headerbutton";

export const Header = (props) => {
  const { companyDetails = {} } = useSelector<any, any>(state => getOrganizationState(state))
  const {selectedCard, handleTakeTagsClick } = props;

  return <>
    <div className="header-container">
      <div className="company-info">
        <div className="header-item">
          <LoginAngel className="logo-angel" />
        </div>
        <div className="header-item second">
          <div className="organization-name">{companyDetails.orgName}</div>
          <span className="organization-sub-text">SPONSOR A CHILD TODAY!</span>
          <p className="organization-tag-taking">{companyDetails.onlineTagTakingBannerInstructions}</p>
        </div>
        <div className="header-item third">
          <Button text={`TAKE (${selectedCard}) TAGS `} onClick={handleTakeTagsClick}/>
        </div>
      </div>
      <div className="img-container" style={{ backgroundImage: `url(${CandyCaneStrip})` }}>
      </div>
    </div>
    <div className="header-offset"></div>
  </>;
}
