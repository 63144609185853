// Set Theme here 3/3 (Assets)
// Unfortunately cannot abstract the "theme" selection path to a variable. "require" is a build time function, so variables are not available yet.
import { THEME } from "../config/index";
import { Themes } from "./theme";

import arrow from "../assets/images/pfchristmas/arrow.png";
import checkmark from "../assets/images/pfchristmas/checkmark.png";
import outlinedAngel from "../assets/images/pfchristmas/outlinedAngel.png";
import candyCaneStrip from "../assets/images/pfchristmas/candy-cane-strip.png";
import checkbox from "../assets/images/pfchristmas/checkbox.svg";

import arrow2 from "../assets/images/pfbrand/arrow.png";
import checkmark2 from "../assets/images/pfbrand/checkmark.png";
import outlinedAngel2 from "../assets/images/pfbrand/outlinedAngel.png";
import candyCaneStrip2 from "../assets/images/pfbrand/candy-cane-strip.png";
import checkbox2 from "../assets/images/pfbrand/checkbox.svg";

import emojiPrimary0 from "../assets/images/pfchristmas/emojiPrimary0.png";
import emojiPrimary1 from "../assets/images/pfchristmas/emojiPrimary1.png";
import emojiPrimary2 from "../assets/images/pfchristmas/emojiPrimary2.png";
import emojiPrimary3 from "../assets/images/pfchristmas/emojiPrimary3.png";
import emojiPrimary4 from "../assets/images/pfchristmas/emojiPrimary4.png";
import emojiPrimary5 from "../assets/images/pfchristmas/emojiPrimary5.png";
import emojiPrimary6 from "../assets/images/pfchristmas/emojiPrimary6.png";
import emojiPrimary7 from "../assets/images/pfchristmas/emojiPrimary7.png";

import emojiPrimary8 from "../assets/images/pfbrand/emojiPrimary0.png";
import emojiPrimary9 from "../assets/images/pfbrand/emojiPrimary1.png";
import emojiPrimary10 from "../assets/images/pfbrand/emojiPrimary2.png";
import emojiPrimary11 from "../assets/images/pfbrand/emojiPrimary3.png";
import emojiPrimary12 from "../assets/images/pfbrand/emojiPrimary4.png";
import emojiPrimary13 from "../assets/images/pfbrand/emojiPrimary5.png";
import emojiPrimary14 from "../assets/images/pfbrand/emojiPrimary6.png";
import emojiPrimary15 from "../assets/images/pfbrand/emojiPrimary7.png";

import emojiSecondary0 from "../assets/images/pfchristmas/emojiSecondary0.png";
import emojiSecondary1 from "../assets/images/pfchristmas/emojiSecondary1.png";
import emojiSecondary2 from "../assets/images/pfchristmas/emojiSecondary2.png";
import emojiSecondary3 from "../assets/images/pfchristmas/emojiSecondary3.png";
import emojiSecondary4 from "../assets/images/pfchristmas/emojiSecondary4.png";
import emojiSecondary5 from "../assets/images/pfchristmas/emojiSecondary5.png";
import emojiSecondary6 from "../assets/images/pfchristmas/emojiSecondary6.png";
import emojiSecondary7 from "../assets/images/pfchristmas/emojiSecondary7.png";

import emojiSecondary8 from "../assets/images/pfbrand/emojiSecondary0.png";
import emojiSecondary9 from "../assets/images/pfbrand/emojiSecondary1.png";
import emojiSecondary10 from "../assets/images/pfbrand/emojiSecondary2.png";
import emojiSecondary11 from "../assets/images/pfbrand/emojiSecondary3.png";
import emojiSecondary12 from "../assets/images/pfbrand/emojiSecondary4.png";
import emojiSecondary13 from "../assets/images/pfbrand/emojiSecondary5.png";
import emojiSecondary14 from "../assets/images/pfbrand/emojiSecondary6.png";
import emojiSecondary15 from "../assets/images/pfbrand/emojiSecondary7.png";
// TODO: Make SVGs import + react component
import { ReactComponent as angel } from "../assets/images/pfchristmas/angel.svg";
import { ReactComponent as errorAngel } from "../assets/images/pfchristmas/errorAngel.svg";
import { ReactComponent as loginAngel } from "../assets/images/pfchristmas/loginAngel.svg";
import { ReactComponent as logoVertical } from "../assets/images/pfchristmas/logo-vertical.svg";

import { ReactComponent as angel2 } from "../assets/images/pfbrand/angel.svg";
import { ReactComponent as errorAngel2 } from "../assets/images/pfbrand/errorAngel.svg";
import { ReactComponent as loginAngel2 } from "../assets/images/pfbrand/loginAngel.svg";
import { ReactComponent as logoVertical2 } from "../assets/images/pfbrand/logo-vertical.svg";

import emailIcon from "../assets/images/pfchristmas/email2.svg";
import phoneIocn from "../assets/images/pfchristmas/phone2.svg";
import profileIcon from "../assets/images/pfchristmas/profile2.svg";

import emailIcon2 from "../assets/images/pfbrand/email2.svg";
import phoneIocn2 from "../assets/images/pfbrand/phone2.svg";
import profileIcon2 from "../assets/images/pfbrand/profile2.svg";

export const primaryEmojis =
  THEME === Themes.PF_CHRISTMAS
    ? [
        emojiPrimary0,
        emojiPrimary1,
        emojiPrimary2,
        emojiPrimary3,
        emojiPrimary4,
        emojiPrimary5,
        emojiPrimary6,
        emojiPrimary7,
      ]
    : [
        emojiPrimary8,
        emojiPrimary9,
        emojiPrimary10,
        emojiPrimary11,
        emojiPrimary12,
        emojiPrimary13,
        emojiPrimary14,
        emojiPrimary15,
      ];

export const secondaryEmojis =
  THEME === Themes.PF_CHRISTMAS
    ? [
        emojiSecondary0,
        emojiSecondary1,
        emojiSecondary2,
        emojiSecondary3,
        emojiSecondary4,
        emojiSecondary5,
        emojiSecondary6,
        emojiSecondary7,
      ]
    : [
        emojiSecondary8,
        emojiSecondary9,
        emojiSecondary10,
        emojiSecondary11,
        emojiSecondary12,
        emojiSecondary13,
        emojiSecondary14,
        emojiSecondary15,
      ];

export const Angel = THEME === Themes.PF_CHRISTMAS ? angel : angel2;
export const Arrow = THEME === Themes.PF_CHRISTMAS ? arrow : arrow2;
export const Checkmark = THEME === Themes.PF_CHRISTMAS ? checkmark : checkmark2;
export const ErrorAngel =
  THEME === Themes.PF_CHRISTMAS ? errorAngel : errorAngel2;
export const LoginAngel =
  THEME === Themes.PF_CHRISTMAS ? loginAngel : loginAngel2;
export const LogoVertical =
  THEME === Themes.PF_CHRISTMAS ? logoVertical : logoVertical2;
export const OutlinedAngel =
  THEME === Themes.PF_CHRISTMAS ? outlinedAngel : outlinedAngel2;
export const CandyCaneStrip =
  THEME === Themes.PF_CHRISTMAS ? candyCaneStrip : candyCaneStrip2;
export const EmailIcon = THEME === Themes.PF_CHRISTMAS ? emailIcon : emailIcon2;
export const PhoneIocn = THEME === Themes.PF_CHRISTMAS ? phoneIocn : phoneIocn2;
export const ProfileIcon =
  THEME === Themes.PF_CHRISTMAS ? profileIcon : profileIcon2;
export const Checkbox = THEME === Themes.PF_CHRISTMAS ? checkbox : checkbox2;
