import { createRoutine } from "redux-saga-routines";
import { DEFAULT_PAGINATION_PARAMS } from "../constants/app-consts";

export const getChildren = createRoutine("GET_CHILDREN");
export const sendGifts = createRoutine("SEND_GIFTS");

export const INITIAL_STATE = {
  error: false,
  success: false,
  message: "",
  loading: false,
  updating: false,
  children: [] as any[],
  giftsLoading: false,
};

export default (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case getChildren.TRIGGER:
      return {
        ...state,
        children:
          payload.start === DEFAULT_PAGINATION_PARAMS.start
            ? []
            : state.children,
        loading: true,
        message: "",
        success: false,
        error: false,
      };
    case getChildren.SUCCESS:
      const allGifts = [...state.children, ...payload];
      const hasMoreData =
        allGifts.length < DEFAULT_PAGINATION_PARAMS.maxNumLoadable &&
        payload.length >= DEFAULT_PAGINATION_PARAMS.limit;

      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        children: allGifts,
        count: 4,
        hasMoreData,
      };
    case getChildren.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: payload,
      };

    case sendGifts.TRIGGER:
      return {
        ...state,
        loading: true,
        message: "",
        success: false,
        error: false,
        giftsLoading: true,
      };
    case sendGifts.SUCCESS:
      let { children } = state;
      if (Array.isArray(payload) && Array.isArray(children)) {
        children = children.filter(
          ({ id } = {}) =>
            payload.findIndex((gift = {}) => gift.id === id) === -1
        );
      }
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        gifts: payload,
        children,
        giftsLoading: false,
      };
    case sendGifts.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: payload,
        giftsLoading: false,
      };

    default:
      return { ...state };
  }
};
