import { call, put, takeLatest } from "redux-saga/effects";

import Api from "../services/ApiCaller";
import { getRefData } from "../redux/refdata.redux";

function* getRefDataRequest(api) {
    try {
        let parameters = {};
        const { res = {} } = yield call(Api.callServer, api, parameters);

        if (res && res.refData) {
            yield put(getRefData.success(res.refData));
        } else {
            yield put(getRefData.failure(''));
        }
    } catch (e) {
        yield put(getRefData.failure(e));
    } finally {
        yield put(getRefData.fulfill())
    }
}

export default (api) => {
    const getRefDataApi = (data) => api.get("/RefData", data);

    return [
        takeLatest(getRefData.TRIGGER as any, getRefDataRequest, getRefDataApi),
    ];
};
