import storage from 'redux-persist/lib/storage';
import { createMigrate } from 'redux-persist';
import { INITIAL_STATE as REFDATA_INITIAL_STATE } from '../redux/refdata.redux';
import { INITIAL_STATE as COMPANY_INITIAL_STATE } from '../redux/company.redux';
import { INITIAL_STATE as CHILDREN_INITIAL_STATE } from '../redux/children.redux';
import { INITIAL_STATE as ACCOUNT_INITIAL_STATE } from '../redux/account.redux';

export const APP_INITIAL_STATE = {
  refData: REFDATA_INITIAL_STATE,
  company: COMPANY_INITIAL_STATE,
  children: CHILDREN_INITIAL_STATE,
  account: ACCOUNT_INITIAL_STATE,
}

const migrations: any = {
  1: _state => ({...APP_INITIAL_STATE}),
};

const REDUX_PERSIST = {
  active: true,
  storeConfig: {
     key: 'root',
     storage,
     version: 1,
     migrate: createMigrate(migrations),
     whitelist: [], // An empty whitelist blacklists all reducers
  },
  throttle: 1000,
  social: {
     FB: process.env.FACEBOOK_APP_ID,
     Google: process.env.GOOGLE_CLIENT
  }
};

export default REDUX_PERSIST;
