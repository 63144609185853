import React, { Component } from 'react';
import "./checkbox.scss";
import { Checkmark } from '../../../constants/images';
import { Checkbox } from '../../../constants';
export default class CheckBox extends Component<any, any> {
    render() {
        const { checked, onClick, small } = this.props;

        const styleCheckbox = {
            height: small ? '25px' : '35px',
            cursor: 'pointer'
        };
        
        const styleCheckmark = {
            marginLeft: small ? '-18px' : '-27px',
            marginTop: "5px",
            height: small ? '15px' : '15px',
            cursor: 'pointer'
        };

        return (
            <div onClick={onClick} style={{ display: 'flex', width: small ? '34px' : '45px'}} className='checkbox-small' >
                <img src={Checkbox} style={styleCheckbox} alt="" />
                {checked && <img src={Checkmark} style={styleCheckmark} alt="" />}
            </div>
        )
    }
}