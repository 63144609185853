import React, { Component } from 'react';

export default class Card extends Component<any, any> {
    render() {
        return (
            <div className={`${this.props.className} font-fam`} onClick={this.props.onClick} > {this.props.children}</div>
        )
    }
}

