import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
export default class CustomModal extends Component<any, any> {
   render() {
      const { modalBodyComponent, ontoggleClick, isOpen, classes } = this.props;

      return (
         <Modal isOpen={isOpen} toggle={ontoggleClick} className={classes} backdrop={true}>
            {this.props.modalHeaderText && <ModalHeader>{this.props.modalHeaderText}</ModalHeader>}
            <ModalBody>
               {modalBodyComponent}
            </ModalBody>
         </Modal>
      )
   }
}

