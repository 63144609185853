import React, { Component, Fragment } from 'react';
import { Modal } from '../modal';
class BackDrop extends Component<any, any> {
    render() {
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.show}
                    modalBodyComponent={this.props.modalBodyComponent}
                    modalHeaderText={this.props.modalHeaderText}
                    ontoggleClick={this.props.ontoggle}
                    classes={this.props.classes}
                />
            </Fragment>
        )
    }
}

export default BackDrop;