import React, { ChangeEvent } from "react";
import Slider from "@material-ui/core/Slider";
import { useState } from "react";
import _ from "lodash";

interface IAgeSliderProps {
  onChangeCommitted?: (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => void;
}

const MIN_VALUE = 0;
const MAX_VALUE = 18;
const DEFAULT_MARKERS = [
  {
    value: MIN_VALUE,
    label: MIN_VALUE.toString(),
  },
  {
    value: MAX_VALUE,
    label: MAX_VALUE.toString(),
  },
];

const AgeSlider = (props: IAgeSliderProps) => {
  const { onChangeCommitted } = props;
  const [marks, setMarks] = useState(DEFAULT_MARKERS);
  const [value, setValue] = useState<number | number[]>([MIN_VALUE, MAX_VALUE]);

  const onSliderChange = (
    _event: React.ChangeEvent<{}>,
    item: number | number[]
  ) => {
    setValue(item);
    setMarks(
      _.uniqBy(
        typeof item === "number"
          ? [
              ...DEFAULT_MARKERS,
              {
                value: item,
                label: item?.toString(),
              },
            ]
          : [
              ...DEFAULT_MARKERS,
              ...(item as number[]).map((i) => ({
                value: i,
                label: i.toString(),
              })),
            ],
        "value"
      )
    );
  };

  return (
    <div className="ageSlider-styles">
      <Slider
        getAriaLabel={(index: number) => `age slider ${index}`}
        value={value}
        onChange={onSliderChange}
        onChangeCommitted={(event, items) =>
          onChangeCommitted && onChangeCommitted(event, items)
        }
        min={MIN_VALUE}
        max={MAX_VALUE}
        marks={marks}
      />
    </div>
  );
};

export default AgeSlider;
