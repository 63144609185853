import { Component } from 'react';
import { LoginAngel } from '../../constants/images';
import './error.scss';

export class ErrorPage extends Component {
    render() {
        return (
            <div className='error-message-container'>
                <LoginAngel className="error-image" />
                <div className='error-message'><b>
                    <p className="error-msg" style={{marginTop: '4%'}}>Contact your Organization to get the specific link for your AngelTree tag list.</p>
                    </b></div>
            </div>
        );
    };
}