import { createRoutine } from "redux-saga-routines";
export const getCompany = createRoutine("getCompany");

export const INITIAL_STATE = {
  count: 0,
  error: false,
  success: false,
  message: "",
  loading: false,
  updating: false,
  companyDetails: {},
};

export default (state = INITIAL_STATE, { type, payload }: {type: string; payload: any}) => {
  switch (type) {
    case getCompany.TRIGGER:
      return { ...state, loading: true, message: "", success: false, error: false };
    case getCompany.SUCCESS:
      payload.logoUrl = payload.logoUrl || 'https://images4.alphacoders.com/690/thumb-1920-690392.jpg';
      return { ...state, loading: false, success: true, error: false, companyDetails: payload };
    case getCompany.FAILURE:
      return { ...state, loading: false, error: true, success: true, message: payload };

    default:
      return { ...state };
  }
};
