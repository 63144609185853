import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCompany } from '../../redux/company.redux';
import { isEqual } from 'lodash';
import loadingAngel from '../../assets/images/loadingAngel.png';
import angelTree from '../../assets/images/angelTree.png';
import flake from '../../assets/images/flake.png';
import flake2 from '../../assets/images/flake2.png';

class Splash extends Component<any, any> {
  state: { loading: boolean; animate: boolean; done: boolean; };
  props: any;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      animate: false,
      done: false
    }
  }

  componentWillMount() {
    this.props.getCompany(this.props.organizationId);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      if (nextProps.success === true) {
        this.setState({
          loading: false
        })
        if (nextProps.success) {
          setTimeout(() => {
            this.setState({
              done: true
            })
          }, 1000);
          this.setState({ animate: true })
        }
      }

      if (!nextProps.error && !nextProps.loading && nextProps.organizationId && this.state.done) {
        window.location.href = `${window.location.origin}/${this.props.organizationId}/children`;
      }
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className={`App ${this.state.done ? 'hidden' : ''}`}>
        <main className="App-header">
          {loading && <div><i className="fa fa-circle-o-notch fa-spin fa-3x spin-circle"></i></div>}
          <img src={flake} className="flake-top" alt="flake" />
          <Fragment> <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img src={flake} className="flake-left" style={{ height: '100px' }} alt="flake" />
            <img src={loadingAngel} className={this.state.animate ? "App-logo logo-animation" : "App-logo "} alt="logo" />
          </div>
            <Fragment>
              <img src={angelTree} className="title-angel" style={{marginTop: '70px'}} alt="title" />
              <img src={flake2} className="flake-bottom" style={{}} alt="flake" />
            </Fragment>
          </Fragment>
        </main>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    loading: state.company.loading,
    success: state.company.success,
    message: state.company.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompany: (data) => dispatch(getCompany(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
