import {applyMiddleware, compose, createStore} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import ReduxPersist from './config/reduxPersist';
import createSagaMiddleware from 'redux-saga';
import rootReducer, {STARTUP} from './redux'
import rootSaga from './sagas';
import {createBrowserHistory} from 'history';
import { routerMiddleware } from 'connected-react-router';


export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

let finalReducer = rootReducer(history);
if (ReduxPersist.active) {
  const persistConfig = ReduxPersist.storeConfig;
  finalReducer = persistReducer(persistConfig, rootReducer(history)) as any;
}

export const store = createStore(
  finalReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);

export const persistor = persistStore(store, undefined, () => {
   store.dispatch(STARTUP.trigger());
});

sagaMiddleware.run(rootSaga);
