import { all } from 'redux-saga/effects';
import API from '../services/Api';
import { BASEURL } from '../constants/urls';
import initRefDataSaga from './refdata.sagas'
import initCompanySaga from './company.sagas';
import initChildrenSaga from './children.sagas';
import initAccountSaga from './account.sagas';
const { create } = API;

export const api = create(BASEURL);

export default function* root() {
   yield all([
      ...initRefDataSaga(api),
      ...initCompanySaga(api),
      ...initChildrenSaga(api),
      ...initAccountSaga(api),
   ]);
};
