import apisauce from 'apisauce';
import {BASEURL} from '../constants/urls'

const create = (baseURL = BASEURL) => {
   const api = apisauce.create({
      baseURL,
      headers: {
         'Cache-Control': 'no-cache',
         'Content-Type': 'application/json'
      },
      timeout: 60000
   });

   return {
      ...api,
   };
};

export default {
   create
};

