import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { EGender, EGenderMap } from "../../constants/enums";

interface Props {
  isBoyChecked: boolean;
  isGirlChecked: boolean;
  genders: EGender[];
  handleClick: (value) => void;
}
export const GenderFilter = (props: Props) => {
  const { isBoyChecked, isGirlChecked, handleClick, genders } = props;
  return (
    <FormGroup className="genderFilter-styles">
      {genders.map((gender) => (
        <FormControlLabel
          key={`gender-filter-checkbox-${gender.toString()}`}
          control={
            <Checkbox
              checked={
                gender.toString() === EGender[EGender.M]
                  ? isBoyChecked
                  : isGirlChecked
              }
              onChange={() => handleClick(gender)}
            />
          }
          label={EGenderMap[gender]}
        />
      ))}
    </FormGroup>
  );
};

export default GenderFilter;
