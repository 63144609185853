import React, { Fragment } from 'react';
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { logout } from './redux/account.redux';

import { Children } from './components/childrens';

import './config/reduxPersist';
import './AppRouter.scss';
import { ErrorPage } from './components/error';
import { THEME } from './config';


const CompanyRoute: React.FC<any> = ({ component: Component, companyDetails, ...rest }) => {
  return <Route
    {...rest}
    render={(props: JSX.IntrinsicAttributes) => {
      return companyDetails.publicId ? <Component {...props} /> : null;
    }}
  />;
}

const getFaviconEl = (): HTMLLinkElement => {
  return document.getElementById("favicon") as HTMLLinkElement;
}

class AppRouter extends React.Component<any, any> {
  constructor(props?: any) {
    super(props);

    this.state = {
      path: '',
      open: false,
      renderHeader: true,
      authenticated: false
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  async componentDidMount() {
    this.onRouteChanged();

    const favicon = getFaviconEl();
    const isLocal = window.location.hostname === 'localhost';
    favicon.href = `${isLocal ? '/' : process.env.PUBLIC_URL}${THEME}/favicon.ico`;
  }

  onRouteChanged() {
    const { location: { pathname } } = this.props;
    if (pathname === "/children") {
      this.setState({ renderHeader: true });
    } else {
      this.setState({ renderHeader: false });
    }
  }

  toggleCollapse = () => {
    this.setState((prevState: { open: any; }) => ({ open: !prevState.open }));
  };

  logoutUser = () => {
    this.toggleCollapse();
    this.props.logoutUser();
  }

  render() {
    const { companyDetails } = this.props;

    return (
      <Fragment>
        <Switch>
          <CompanyRoute path="/children" component={Children} companyDetails={companyDetails} />
          <Redirect from="/" exact to="/children" />
        </Switch>
        {this.props.error && !companyDetails.publicId && <ErrorPage />}
      </Fragment>
    );
  }
}
const mapStateToProps = (state: { company: { companyDetails: any; error: any; } }) => {
  return {
    companyDetails: state.company.companyDetails,
    error: state.company.error
  };
};

const mapDispatchToProps = (dispatchEvent: any) => {
  return {
    logoutUser: () => dispatchEvent(logout())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRouter));
