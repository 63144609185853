import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./AppRouter";

import { persistor, store } from "./store";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import "../node_modules/font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import { ErrorPage } from "./components/error";
import { Splash } from "./components/Splash";
import { Loading } from "./components/common/loading";
import { Offline } from "./components/offline/offline";
import { getCookie, setCookie } from "./helper";
import { getRefData } from "./redux/refdata.redux";

let organizationId = window.location.pathname.split("/")[1];
if (!organizationId) {
  organizationId = getCookie("v-organization-id");
} else {
  setCookie("v-organization-id", organizationId);
}
const App: React.FC = () => {
  return (
    <Provider store={store as any}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <AppInternal />
      </PersistGate>
    </Provider>
  );
};

const AppInternal: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRefData());
  }, []);

  const refData = useSelector<any, any>((state) => state.refData);
  const isAppOffline =
    refData.hasBeenRequested && !refData.isLoading && refData.isError;

  if (isAppOffline) {
    return <Offline />;
  }
  if (!organizationId) {
    return <ErrorPage />;
  }
  return (
    <>
      <Splash organizationId={organizationId} />
      <BrowserRouter basename={organizationId}>
        <AppRouter />
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
