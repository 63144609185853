import {combineReducers} from 'redux';
import { History } from 'history';
import { createRoutine } from 'redux-saga-routines';
import { connectRouter } from 'connected-react-router'

import refData from './refdata.redux';
import company from './company.redux';
import children from './children.redux';
import account from './account.redux';


export const STARTUP = createRoutine('STARTUP');

export default (history: History) => 
   combineReducers({
      router: connectRouter(history) as any,
      refData: refData as any,
      company: company as any,
      children: children as any,
      account: account as any,
   });
