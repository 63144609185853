import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { toast } from "react-toastify";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { createSponsor } from "../../../redux/account.redux";
import {
  Button,
  TextInput,
  Loading as LoadingProgress,
  CheckBox,
} from "../../common";
import { Form_Fields } from "../../../constants";
import { LoginAngel } from "../../../constants/images";

class Account extends Component<any, any> {
  constructor(props?: any) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      overEighteen: 0,
    };
  }
  componentDidUpdate(prevProps?: any) {
    if (!isEqual(this.props, prevProps)) {
      const { success, authenticated, sponsorSuccess, onSuccess } = this.props;
      if (success || authenticated || sponsorSuccess) {
        setTimeout(onSuccess, 100);
      }
    }
  }

  changeState = (name?: any, event?: any) => {
    let { target: { value = "" } = {} } = event;
    if (name !== "name") {
      value = value.trim();
    }
    this.setState({
      ...this.state,
      [name]: name === "phone" ? getFormattedPhone(value) : value,
    });
  };

  _renderSignupFields = () => {
    const { Signup_Fields = [] } = Form_Fields;
    return Signup_Fields.map((item, index) => {
      const { type, placeholder, name, required, icon } = item;
      return (
        <div className="form-group" key={index}>
          <TextInput
            {...item}
            InputType={type}
            onChange={(event) => this.changeState(name, event)}
            placeHolder={placeholder}
            className={"form-control login-field"}
            name={name}
            value={this.state[name] || ""}
            required={required}
            icon={icon}
          />
        </div>
      );
    });
  };

  submitEvent = (event?: any) => {
    event.preventDefault();
    const { email, phone, overEighteen } = this.state;
    let { name } = this.state;
    name = (name || "").trim();

    const validPhone = formatNumber(phone);

    if (name === "") {
      toast("Name cannot be empty.");
      return;
    }

    if (phone && !isValidPhoneNumber(validPhone.trim())) {
      toast("Invalid Phone Number."); // Try matching the format +12345678901');
      return;
    }

    if (!isValidEmail(email)) {
      toast("Invalid Email Address");
      return;
    }

    if (overEighteen === 0) {
      toast("Please verify that you are 18 years or older.");
      return;
    }
    const data = {
      user: {
        name,
        email,
        phone: validPhone,
        overEighteen: overEighteen, // ? 1 : 0
      },
    };
    this.props.createSponsor(data);
  };

  verifyAge = () => {
    const { overEighteen } = this.state;
    this.setState({ overEighteen: overEighteen === 1 ? 0 : 1 });
  };

  render() {
    const {
      loading,
      sponsorLoading,
      companyDetails: { logoUrl, orgName },
    } = this.props;
    const { overEighteen } = this.state;

    return (
      <main className="login-container">
        {(loading || sponsorLoading) && <LoadingProgress fullPage={true} />}
        <p className="title">Welcome to the Prison Fellowship</p>
        <p className="title">Angel Tree For:</p>
        <div className="p-2 bd-highlight">
          <div className="img-container">
            <span style={{ backgroundImage: `url(${logoUrl}` }} />
          </div>
        </div>
        <p className="title name">{orgName}</p>
        <form style={{ width: "100%" }} onSubmit={this.submitEvent}>
          {this._renderSignupFields()}
          <div className="checkbox-container">
            <p className="user-certify mb-0">
              I certify that I am 18 years old or older
            </p>
            <CheckBox
              small={true}
              onClick={this.verifyAge}
              checked={overEighteen === 1}
            />
          </div>
          <Button className="login-btn" type="submit" text="Take These Tags" />
        </form>
      </main>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyDetails: state.company.companyDetails,
    success: state.account.success,
    loading: state.account.loading,
    sponsorLoading: state.account.sponsor.loading,
    sponsorSuccess: state.account.sponsor.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSponsor: (data) => dispatch(createSponsor(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Account);

// to format and verify either phone number is valid or not
export const formatNumber = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
  } catch (e) {
    return phoneNumber;
  }
};

// to show phone into (512) 353-5623 format
export const getFormattedPhone = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
  } catch (e) {
    return phoneNumber;
  }
};

export const isValidPhoneNumber = (phoneNumber) => {
  /*
    Phone number validation using google-libphonenumber
    */
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return phoneUtil.isPossibleNumber(parsedNumber);
  } catch (e) {
    console.error(e.message);
  }
  return false;
};

export const isValidEmail = (email) => {
  let re =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z0-9]+$/;
  return re.test(email);
};
