import React from 'react';
import { Loading } from '../loading';

export default (props?: any) => {
   const { id = "", className = '', color = 'primary', text, loading = false, disabled, onClick, variant, ...otherProps} = props;
   return (
      <button id={id} color={color} {...otherProps} className={`button  ${className} ${variant === 'link' ? 'btn-link' : ''} `} disabled={disabled} onClick={onClick}>
         {text} {loading && <Loading />}
      </button>
   )
}