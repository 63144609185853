import React, { Component } from "react";

export default class TextInput extends Component<any, any> {
  constructor(props?: any) {
    super(props);
    this.state = {
      errMsg: "",
    };
  }

  _renderInputText = () => {
    const {
      name,
      icon,
      InputType,
      placeHolder,
      disabled,
      required,
      className = "",
      value,
      onChange,
      id,
      ...otherProps
    } = this.props;
    if (name !== "phoneNumber") {
      return (
        <div className="input-container">
            <input
              {...otherProps}
              value={value}
              type={InputType}
              required={required}
              disabled={disabled}
              placeholder={placeHolder}
              onChange={onChange}
              className={`input ${className}`}
              id={id}
              autoComplete="off"
            />
          <div className="icon-container">
            <img src={icon} style={{width: "25px", height: "25px"}} alt={value} />
          </div>
        </div>
      );
    }
  };

  render() {
    const {
      errorMsg,
      name,
      error,
      passMisMatchError,
      isMatchPass,
      smallInput,
    } = this.props;
    return (
      <div style={smallInput ? {} : { width: "100%" }}>
        {this._renderInputText()}
        {error && (
          <span style={{ color: "red", fontSize: "12px" }}> {errorMsg} </span>
        )}
        {isMatchPass && name === "confirmPassword" && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {" "}
            {passMisMatchError}{" "}
          </span>
        )}
      </div>
    );
  }
}
