import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import Api from "../services/ApiCaller";
import { getChildren, sendGifts } from "../redux/children.redux";

function* getChildrenRequest(api, { payload }) {
  try {
    let parameters = {
      max_age: payload.max_age,
      min_age: payload.min_age,
      start: payload.start,
      limit: payload.limit,
      ...(payload.gender && { gender: payload.gender }),
      unclaimed: "True",
      populate: "True",
    };
    const { res = {} } = yield call(Api.callServer, api, parameters);

    var success = true;
    if (success && res && res.gifts) {
      yield put(getChildren.success(res.gifts));
    } else {
      yield put(getChildren.failure(""));
    }
  } catch (e) {
    yield put(getChildren.failure(e));
  }
}

function* sendGiftsRequest(api, { payload }) {
  try {
    const { res = {} } = yield call(Api.callServer, api, payload);
    var success = true;
    if (success && res && res.gifts) {
      yield put(sendGifts.success(res.gifts));
    } else {
      yield put(sendGifts.failure(""));
    }
  } catch (e) {
    toast(e.message || e);
    yield put(sendGifts.failure(e));
  }
}

export default (api) => {
  const getChildrenApi = (data) => api.get("/Gifts", data);
  const sendGiftsApi = (data) => api.put("/Gifts", data);

  return [
    takeLatest(getChildren.TRIGGER as any, getChildrenRequest, getChildrenApi),
    takeLatest(sendGifts.TRIGGER as any, sendGiftsRequest, sendGiftsApi),
  ];
};
