import { call } from 'redux-saga/effects';
import { inRange } from 'lodash';
import { toast } from 'react-toastify';

export class CustomError extends Error {
   statusCode;
   data;

   constructor(statusCode = 503, data, ...params) {
      super(...params);
      Error.captureStackTrace(this, CustomError);
      this.statusCode = statusCode;
      this.data = data;
   }
}

function* callServer(apiFunction, reqData, showError = true) {
   const response = yield call(apiFunction, reqData, reqData.id);
   const {
      data = {}, ok = false, problem = 'TIMEOUT_ERROR', status = 500
   } = response || {};
   let resData = data
   if (resData === null) resData = {}
   if (showError && !ok && !resData.success && (resData.message || resData.error)) {
      if (status === 403) {
         toast('You do not have access to this service. Please contact your administrator.');
      } else if (status === 404) {
         toast(resData.message || resData.error);
      }else if (status === 502) {
         toast(`API call to ${response.config.url} timed out. Please try again.`);
      } else if (inRange(status, 500, 600)) {
         toast('Service not available. Try again later.');
      } else {
         notify(resData.message);
         const message = extractMessage(resData, problem);
         throw new CustomError(status, resData, message);
      }
   }
   if (ok) {
      return { res: resData, statusCode: status };
   }
   return { res: undefined };
}

export const extractMessage = (resData, problem) => {
   if (resData) {
      if (typeof resData.error === 'object' && resData.error.message) {
         return resData.error.message;
      }
      if (resData.message) {
         return resData.message;
      }
      if (resData.error) {
         return resData.error;
      }
      if (resData) {
         return resData;
      }
      return getMessage(resData);
   }
   return getMessage(problem);
};

const getMessage = (error) => {
   if (error === 'TIMEOUT_ERROR') {
      return 'No Response From Server.';
   }
   if (error === 'CONNECTION_ERROR') {
      return 'Server Is Not Available.';
   }
   if (error === 'NETWORK_ERROR') {
      return 'Network not available.';
   }
   return 'Something went wrong. Please try again';
};

const notify = (message) => {
};


export default {
   callServer
};
