import { call, put, takeLatest } from "redux-saga/effects";
import { ApisauceInstance } from 'apisauce';
import Api from "../services/ApiCaller";
import { getCompany } from "../redux/company.redux";

function* getCompanyRequest({ api, setHeader, deleteHeader }, { payload }: {payload: any}) {
  try {

    setHeader('publicid', payload);
    const { res = {} } = yield call(Api.callServer, api, {});

    var success = true;
    if (success && res && res.organization) {
      yield put(getCompany.success(res.organization));
    } else {
      yield put(getCompany.failure('Organization not found'));
    }
  } catch (e) {
    yield put(getCompany.failure(e));
  }
}

export default (api: ApisauceInstance) => {
  const getCompanyapi = (data: any) => api.get("/Organizations", data);
  return [takeLatest(getCompany.TRIGGER as any, getCompanyRequest, { api: getCompanyapi, setHeader: api.setHeader, deleteHeader: api.deleteHeader })];
};
