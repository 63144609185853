import React from 'react';

export default (props?: any) => {
   const { text, disabled, onClick, checked} = props;
   return (
      <button 
      className={checked? "card-btn-container-checked card-btn": "card-btn"}
       style={{
         background: checked? "white": "",
         opacity: checked? .5: 1
      }} 
      disabled={disabled} 
      onClick={onClick}>
         {text}
      </button>
   )
}