import React, { Component } from "react";
import { connect } from "react-redux";
import { getChildren, sendGifts } from "../../redux/children.redux";
import { isEmpty } from "lodash";
import { BackDrop, Loading as LoadingProgress } from "../common";
import { Account as CreateAccount } from "../account/createAccount";
import { Header } from "../common";
import GenderFilter from "../genderfilter";
import Slider from "../ageslider";
import { toast } from "react-toastify";
import { EGender } from "../../constants/enums";
import { VAT_URL } from "../../constants";
import { ChildCard } from "../common/childCard/childCard";
import Button from "../common/button/headerbutton";
import { DEFAULT_PAGINATION_PARAMS } from "../../constants/app-consts";

interface IState {
  start: number;
  limit: number;
  minAge?: number;
  maxAge?: number;
  selectedIds: number[];
  isModalVisible: boolean;
  isBoyChecked: boolean;
  isGirlChecked: boolean;
}

class Children extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    const start = DEFAULT_PAGINATION_PARAMS.start;
    const limit = DEFAULT_PAGINATION_PARAMS.limit;

    this.state = {
      start,
      limit,
      minAge: undefined,
      maxAge: undefined,
      selectedIds: [],
      isModalVisible: false,
      isBoyChecked: false,
      isGirlChecked: false,
    };

    props.getChildren({ start, limit });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const { success, giftsLoading } = nextProps;
    const { success: prevSuccess, giftsLoading: prevGiftsLoading } = prevState;
    const state = { success, giftsLoading };
    if (!giftsLoading && prevGiftsLoading && success && !prevSuccess) {
      (state as any).selectedIds = [];
      (state as any).isModalVisible = false;
      toast("Tags taken!");
    }
    return state;
  }

  handleTakeTagsClick = () => {
    if (!!this.state.selectedIds.length) {
      this.setState({
        isModalVisible: true,
      });
    }
  };

  getGenderFilterValue = (isBoyChecked?: boolean, isGirlChecked?: boolean) => {
    const {
      isBoyChecked: isBoyCheckedFromState,
      isGirlChecked: isGirlCheckedFromState,
    } = this.state;

    if (isBoyChecked === undefined) {
      isBoyChecked = isBoyCheckedFromState;
    }
    if (isGirlChecked === undefined) {
      isGirlChecked = isGirlCheckedFromState;
    }

    let genderFilterValue = "";
    if (isBoyChecked && !isGirlChecked) {
      genderFilterValue = EGender[EGender.M];
    } else if (!isBoyChecked && isGirlChecked) {
      genderFilterValue = EGender[EGender.F];
    }

    return genderFilterValue;
  };

  applyGenderFilter = (gender: string) => {
    const start = DEFAULT_PAGINATION_PARAMS.start;
    const { minAge, maxAge, isBoyChecked, isGirlChecked, limit } = this.state;

    const newIsBoyChecked =
      gender === EGender[EGender.M] ? !isBoyChecked : isBoyChecked;
    const newIsGirlChecked =
      gender === EGender[EGender.F] ? !isGirlChecked : isGirlChecked;

    this.setState({
      isBoyChecked: newIsBoyChecked,
      isGirlChecked: newIsGirlChecked,
      selectedIds: [],
      start,
    });
    this.props.getChildren({
      start,
      limit,
      gender: this.getGenderFilterValue(newIsBoyChecked, newIsGirlChecked),
      min_age: minAge,
      max_age: maxAge,
    });
  };

  onSliderChangeCommitted = (
    _event: React.ChangeEvent<{}>,
    item: number | number[]
  ) => {
    const { limit } = this.state;

    const start = DEFAULT_PAGINATION_PARAMS.start;
    const min = (item && item[0]) || 0;
    const max = (item && item[1]) || 17;

    if (this.state.minAge !== min || this.state.maxAge !== max) {
      this.setState({
        minAge: min,
        maxAge: max,
        selectedIds: [],
        start,
      });

      this.props.getChildren({
        min_age: min,
        max_age: max,
        start,
        limit,
        gender: this.getGenderFilterValue(),
      });
    }
  };

  loadMoreItems = () => {
    const { minAge, maxAge, start, limit } = this.state;

    const newStart = start + DEFAULT_PAGINATION_PARAMS.limit;

    const params = {
      start: newStart,
      limit,
      gender: this.getGenderFilterValue(),
      min_age: minAge,
      max_age: maxAge,
    };

    this.props.getChildren(params);
    this.setState({
      start: newStart,
    });
  };

  onGiftTagClick = (event: any, id: any) => {
    event.stopPropagation();
    const { selectedIds } = this.state;
    let newIds = selectedIds.includes(id)
      ? selectedIds.filter((selectedId: number) => selectedId !== id)
      : [...selectedIds, id];
    this.setState({
      selectedIds: newIds,
    });
  };

  handleTakeTagsModalConfirmation = () => {
    const { sponsorId, sendGifts } = this.props;
    const gifts = this.state.selectedIds.map((id) => ({ sponsorId, id }));
    sendGifts({ gifts, user_id: sponsorId });
  };

  toggleUserInfoDialog = () => {
    this.setState((prevState) => ({
      isModalVisible: !prevState.isModalVisible,
    }));
  };

  _renderCards() {
    const { selectedIds } = this.state;
    const { childData, virtualPublicId } = this.props;

    const vatUrl = virtualPublicId ? VAT_URL.concat(virtualPublicId) : VAT_URL
    
    return (
      <div className="my-container">
        {childData && !isEmpty(childData) ? (
          childData.map((gift, index) => {
            return (
              <ChildCard
                gift={gift}
                index={index}
                selectedIds={selectedIds}
                onGiftTagClick={this.onGiftTagClick}
              />
            );
          })
        ) : (
          <div className="col-md-12 col-sm-12 col-xs-12 no-record">
            <span className="primary-text-color">
              <em>Praise the Lord! </em> -{" "}
              <span className="secondary-text-color">
                the locally assigned children have all been sponsored!
              </span>
            </span>{" "}
            <br />
            <span className="secondary-text-color">
              There are still children from high need areas across the country
              remaining who have not been matched to a local church. You can
              sponsor them with a Christmas gift card on behalf of their
              incarcerated parent <a href={vatUrl}>here</a>.
            </span>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { loading, giftsLoading, childData, hasMoreData } = this.props;
    const { isModalVisible, selectedIds } = this.state;
    let countSelectedTags = selectedIds.length;

    return (
      <>
        {giftsLoading && <LoadingProgress fullPage={true} />}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Header
            handleTakeTagsClick={this.handleTakeTagsClick}
            selectedCard={countSelectedTags}
          />
          <div className="filter-container">
            <div className="filter-bless-label-container">
              <div className="message search-children-filter">
                FIND A CHILD <br />
                TO BLESS
              </div>
            </div>
            <div className="filter">
              <div className="search-children-gender-filter">
                <div className="search-label"> SEARCH BY GENDER</div>
                <GenderFilter
                  isBoyChecked={this.state.isBoyChecked}
                  isGirlChecked={this.state.isGirlChecked}
                  handleClick={(gender) => this.applyGenderFilter(gender)}
                  genders={this.props.genders}
                />
              </div>
              <div className="search-children-age-filter">
                <div className="age-label">AGE RANGE</div>
                <Slider onChangeCommitted={this.onSliderChangeCommitted} />
              </div>
            </div>
          </div>
          {loading && isEmpty(childData) ? (
            <div className="loading-icon">
              {" "}
              <i className="fa fa-circle-o-notch fa-spin fa-3x"></i>
            </div>
          ) : (
            <div className="child-render-inner-container">
              {this._renderCards()}
              <div className="show-more-button">
                {hasMoreData && (
                  <Button text={`SHOW ME MORE`} onClick={this.loadMoreItems} />
                )}
              </div>
            </div>
          )}
        </div>
        <BackDrop
          show={isModalVisible}
          ontoggle={this.toggleUserInfoDialog}
          classes="code-container"
          modalBodyComponent={
            <CreateAccount onSuccess={this.handleTakeTagsModalConfirmation} />
          }
        />
        {/* <Footer footerLinks={FOOTER_LINKS} /> */}
      </>
    );
  }
}

const mapStateToProps = (
  {
    account = {},
    children = {},
    company = {},
    refData: { genders = [] },
  } = {} as any
) => {
  const {
    loading,
    giftsLoading,
    message,
    success,
    children: childData,
    hasMoreData,
  } = children;
  return {
    loading,
    giftsLoading,
    message,
    success,
    childData,
    hasMoreData,
    sponsorId: account?.sponsor?.currentUser?.id,
    giftsPerChild: company?.companyDetails?.giftsPerChild,
    virtualPublicId: company?.companyDetails?.virtualPublicId,
    genders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChildren: (data) => dispatch(getChildren(data)),
    sendGifts: (data) => dispatch(sendGifts(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Children);
