import {
  ic_home,
  ic_mail_outline,
  ic_person_outline,
  ic_phone_in_talk
} from 'react-icons-kit/md';
import { EmailIcon } from './images';
import { PhoneIocn } from './images';
import { ProfileIcon } from './images';

export const Form_Fields = {
  contactFields: [
      { name: 'name', type: 'text', placeholder: 'Name', icon: ic_person_outline },
      { name: 'email', type: 'email', placeholder: 'Email Address', icon: ic_mail_outline },
      { name: 'phone', type: 'tel', placeholder: 'Phone Number (+920009658265)', icon: ic_phone_in_talk}, // , title: "Format: +12345678901" },
      { name: 'streetAddress', type: 'text', placeholder: 'Street Address', icon: ic_home },
  ],
  Signup_Fields: [
      { name: 'name', type: 'text', placeholder: 'Name', required: true,  icon: ProfileIcon  },
      { name: 'phone', type: 'tel', placeholder: 'Phone Number (+920009658265)', required: false, icon: PhoneIocn /* pattern: "^([+][0-9]+)$",  title: "Format: +12345678901" */ },
      { name: 'email', type: 'text', placeholder: 'Email Address', required: true, icon: EmailIcon }
  ],
  Login_Fields: [
      { name: 'email', type: 'email', placeholder: 'Email Address' },
      { name: 'password', type: 'password', placeholder: 'Password' },
  ],
  ProfileFields: [
      { name: 'name', type: 'text', placeholder: 'Name', required: true },
      { name: 'email', type: 'email', placeholder: 'Email', required: true },
      { name: 'phone', type: 'tel', placeholder: 'Phone Number (+920009658265)', required: false }
  ],
  ForgotPassword_Fields: [
      { name: 'email', type: 'email', placeholder: 'EMAIL ADDRESS' },
  ],
}