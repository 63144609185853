import React, { Component } from 'react';
import { Dropdown } from "semantic-ui-react";

export default class DropdownComponent extends Component<any, any> {
   options = [{ key: 1, text: 'Choice 1', value: 1 }, { key: 2, text: 'Choice 2', value: 2 }]

   render() {
      return (
         <Dropdown placeholder={this.props.text} selection options={this.props.options} error onChange={this.props.FilterBy} />
      )
   }
}
