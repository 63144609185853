import React, { useState } from "react";
import { primaryEmojis } from "../../../constants";
import CardButton from "../button/CardButton";
import { Modal, ModalBody } from "reactstrap";
import { Card } from "../card";
import { EGender } from "../../../constants/enums";
import { useSelector } from "react-redux";
import { getOrganizationState } from "../../../selectors/organization.selector";
import { IChildCardProps } from "../../../types/children";

export const ChildCard: React.FC<IChildCardProps> = ({
  selectedIds,
  onGiftTagClick,
  gift,
  index,
}) => {
  const [isInmateMessageModalVisible, setIsInmateMessageModalVisible] =
    useState(false);
  const [
    isSponsorChildMessageModalVisible,
    setisSponsorChildMessageModalVisible,
  ] = useState(false);
  const giftsPerChildMinValue = 1;

  const giftsPerChild =
    useSelector<any, any>((state) => getOrganizationState(state)).companyDetails
      ?.giftsPerChild || giftsPerChildMinValue;
  const { privacyCodesEnabled = false } = useSelector<any, any>((state) =>
    getOrganizationState(state)
  ).companyDetails;
  const toggleMessage = () => {
    setIsInmateMessageModalVisible(!isInmateMessageModalVisible);
  };

  const toggleSponsorChild = () => {
    setisSponsorChildMessageModalVisible(!isSponsorChildMessageModalVisible);
  };

  const showMessageModal = (event: any) => {
    event.stopPropagation();
    setIsInmateMessageModalVisible(!isInmateMessageModalVisible);
  };

  const showSponsorChildModal = (event: any) => {
    event.stopPropagation();
    setisSponsorChildMessageModalVisible(!isSponsorChildMessageModalVisible);
  };

  const areMultipleGiftsEnabled = () => {
    return (giftsPerChild || giftsPerChildMinValue) > giftsPerChildMinValue;
  };

  const {
    id,
    child,
    fullPrivacyCode,
    description,
    privacyCode: giftNum,
  } = gift;
  const { firstName, inmateMessage, gender, age } = child;
  const childAge = age ? age : '?'
  const giftDesc = description ? ' | ' + description : ""
  const sponsorChild = `${
    gender === EGender[EGender.M] ? "Boy " : "Girl "
  } | Age: ${childAge + " "} ${giftDesc}`;
  const isTagChecked = selectedIds.includes(id);
  const maxSponsorChildLength = 30;
  const maxMessageLength = 95;
  return (
    <Card
      btntext="Sponsor This Child"
      className="child-container"
      key={id}
      onClick={(event) => onGiftTagClick(event, id)}
    >
      <img
        src={primaryEmojis[index % primaryEmojis.length]}
        alt="Avatar"
        className={`child-smiley ${isTagChecked ? "checked-child-smiley" : ""}`}
      />
      <div
        className={`flex-column align-items-center card-container child-card ${
          isTagChecked ? "checked-card" : ""
        }`}
      >
        <div className={`card-outer-header${isTagChecked ? " checked" : ""}`}>
          <div className="flex-column align-items-center child-info">
            <p className="child-name">{`${firstName}${
              privacyCodesEnabled ? ` (${fullPrivacyCode})` : ""
            }`}</p>
          </div>
          {areMultipleGiftsEnabled() && (
            <div className="flex-column align-items-center">
              <p className="child-name-subheading">{`Gift ${giftNum}`}</p>
            </div>
          )}
          <p
            className="details"
            onClick={(event) => showSponsorChildModal(event)}
          >
            {sponsorChild.length > maxSponsorChildLength
              ? sponsorChild.substring(0, maxSponsorChildLength) + "..."
              : sponsorChild}
          </p>
        </div>
        <p
          className="child-description"
          onClick={(event) => showMessageModal(event)}
        >
          <span className="parent-message-title parent-message messageDetails">
            Message:&nbsp;
          </span>
          {!!inmateMessage
            ? `"${
                inmateMessage.length > maxMessageLength
                  ? inmateMessage.substring(0, maxMessageLength) + "..."
                  : inmateMessage
              }"`
            : "No message found."}
        </p>
        <div className="card-btn-container">
          <CardButton
            text={isTagChecked ? `UNSELECT` : `Select ${firstName}`}
            checked={isTagChecked}
          />
        </div>
        <Modal
          isOpen={
            isInmateMessageModalVisible &&
            inmateMessage.length > maxMessageLength
          }
          toggle={toggleMessage}
        >
          <ModalBody>{inmateMessage}</ModalBody>
        </Modal>
        <Modal
          isOpen={
            isSponsorChildMessageModalVisible &&
            sponsorChild.length > maxSponsorChildLength
          }
          toggle={toggleSponsorChild}
        >
          <ModalBody>{sponsorChild}</ModalBody>
        </Modal>
      </div>
    </Card>
  );
};
